import Link from "./link";
import React from "react"
import { domain } from "../utils";
import classNames from "classnames";
import TextMorpher from "./dynamicWords";

const Header = ({hasHash, heading, supheading, button, image, className, withSpace, withFullWidth, largeHeaderText , largeHeadingClassName, showWhiteBtn}) => {

  if (image && !image.url.includes('//')) {
    image.url = image.url;
  }

  return (
    <header className={'header '+className}>
      <div className="container">
        <div className="row">
          <div className={largeHeaderText ?`col-md-12 offset-lg-1 col-lg-10`: (hasHash ? "col-md-8" : (withFullWidth ? `col-md-6` : `col-md-6 offset-lg-1 col-lg-5`))}>
            <h1 className={`header__heading header__heading--space ${largeHeadingClassName}`}>
              <small className="header__preheading">{supheading}</small>
              {hasHash && (<span className="header_hashTag">#</span>)}
              {heading}
            </h1>
          </div>
          {(image || button) && (
            <div className={withFullWidth ? `header__attachment offset-md-3 col-md-3` : `header__attachment offset-md-3 col-md-3 col-lg-2`}>
              {button && (
                <Link
                  className={classNames("button")}
                  to={button.url}
                >
                  <span className="button__text">{button.label}</span>
                </Link>
              )}
              {image && (
                <img src={image.url} alt=""  loading="lazy" />
              )}
            </div>
          )}
        </div>
      </div>
    </header>
  )
}

export default Header
